$(document).ready(function() {

 	/* =====  MOBILE MENU TOGGLE  ====== */
 	$('.offCanvasToggler').click(function(event) {
 		event.preventDefault();
 		$('.off-canvas-nav').toggleClass('active');
 		$('.fullscreen-overlay').toggleClass('active');
 		$('html, body').toggleClass('no-scrolling');
 	});

 	$('.gallery').fancybox(); 
});